import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { NextRacesState, RaceSportType } from './NextRaces.state';
import { RacesRegionToggle } from './racesRegionToggle/RacesRegionToggle';
import {
    HomePageHeader,
    SportPageHeader,
    RacesTypeSelectButtonsWrapper,
    SliderHeader,
} from './racesSlider/NextRacesSlider.style';

interface RacesSliderHeaderPropsType {
    nextRacesState: NextRacesState;
    sport?: RaceSportType;
}

export const NextRacesHomePageHeader = observer('NextRacesHomePageHeader', (props: RacesSliderHeaderPropsType) => {
    const common = useCommon();
    const { racingNextOffToggle } = ConfigComponents.get(common).config;
    const { nextRacesState } = props;

    return (
        <HomePageHeader>
            {racingNextOffToggle ? (
                <RacesTypeSelectButtonsWrapper>
                    <FiltersNewView
                        select={nextRacesState.activeSportTab}
                        filters={nextRacesState.racesTypeFilterButtons}
                    />
                </RacesTypeSelectButtonsWrapper>
            ) : (
                <SliderHeader>
                    <I18n
                        langKey='next-off-slider.header.next-races'
                        defaultText='Next Races'
                    />
                </SliderHeader>
            )}
            {nextRacesState.activeSportTab === 'horseracing' ? (
                <RacesRegionToggle nextRacesState={nextRacesState} />
            ) : null}
        </HomePageHeader>
    );
});

export const NextRacesSportPageHeader = observer('NextRacesSportPageHeader', (props: RacesSliderHeaderPropsType) => {
    const { nextRacesState, sport } = props;

    return (
        <SportPageHeader>
            <SliderHeader>
                <I18n
                    langKey='next-off-slider.header.next-races'
                    defaultText='Next Races'
                />
            </SliderHeader>
            {sport === 'horseracing' ? <RacesRegionToggle nextRacesState={nextRacesState} /> : null}
        </SportPageHeader>
    );
});
