import * as t from 'io-ts';
import { assertNever } from 'src_common/common/assertNever';
import { createGuard } from 'src_common/common/createGuard';

const SportRacesTypeParamIO = t.union([
    t.literal('build-racecard'),
    t.literal('ante-post'),
    t.literal('quick-pick'),
    t.literal('specials'),
    t.literal('list'),
    t.literal('trap-challenge'),
]);

export type SportRacesTypeParamType = t.TypeOf<typeof SportRacesTypeParamIO>;

const isSportRacesTypeParam = createGuard(SportRacesTypeParamIO);

export const getSportRacesTypeParam = (value: string | undefined): SportRacesTypeParamType | null => {
    if (isSportRacesTypeParam(value)) {
        return value;
    } else {
        return null;
    }
};

export type LoginRouteType = {
    name: 'login';
};

export type ForgotPasswordRouteType = {
    name: 'forgot-password';
};

export type SignUpRouteType = {
    name: 'signup';
};

export type HomeRouteType = {
    name: 'homepage';
};

export type InPlayRouteType = {
    name: 'inplay';
};

export type NotFoundType = {
    name: 'error';
};

export type VirtualsRouteType = {
    name: 'virtuals';
};

export type CasinoRouteType = {
    name: 'casino';
    game?: string | null;
    tabId?: string | null;
};

export type LiveCasinoRouteType = {
    name: 'live-casino';
    tabId?: string | null;
};

export type SportRouteType =
    | {
          name: 'sport';
          nameType: 'regular';
          id: string;
      }
    | {
          name: 'sport';
          nameType: 'races';
          id: 'horseracing' | 'greyhoundracing';
          type: SportRacesTypeParamType | null;
          event: null | {
              id: number;
              slug: string;
          };
      };

export type SportSpecialRouteType = {
    name: 'sport-special';
    id: string;
};

export type CompetitionRouteType = {
    name: 'competition';
    id: number;
    slug: string;
};

export type EventRouteType = {
    name: 'event';
    id: number;
    slug: string;
};

export type RacecardMeetingsRouteType = {
    name: 'racecard';
    collection: 'race-meetings';
    racecardBuildIds: Array<number>;
    selected: number | null;
    sport: string;
};

export type RacecardRaceRouteType = {
    name: 'racecard';
    collection: number | 'next-off';
    selected: number | null;
    sport: string;
};

export type NotificationRouteType = {
    name: 'notificationId';
    notificationId: number;
};

export type SearchRouteType = {
    name: 'search';
};

export type NavigationSportsRouteType = {
    name: 'navigation-sports';
};

export type NotificationsListRouteType = {
    name: 'notifications-list';
};

export type StareventsRouteType = {
    name: 'starevents';
};

export type SportsRouteType = {
    name: 'sports';
};

export type RouteViewType =
    | LoginRouteType
    | ForgotPasswordRouteType
    | SignUpRouteType
    | HomeRouteType
    | InPlayRouteType
    | VirtualsRouteType
    | CasinoRouteType
    | LiveCasinoRouteType
    | SportRouteType
    | CompetitionRouteType
    | EventRouteType
    | RacecardMeetingsRouteType
    | RacecardRaceRouteType
    | NotificationRouteType
    | SportSpecialRouteType
    | SearchRouteType
    | NavigationSportsRouteType
    | NotificationsListRouteType
    | StareventsRouteType
    | SportsRouteType
    | NotFoundType;

export const isMainViewRouteEq = (view1: RouteViewType, view2: RouteViewType): boolean => {
    if (view1.name === 'event') {
        return view2.name === 'event' && view2.id === view1.id;
    }

    if (view1.name === 'racecard' && view1.collection === 'race-meetings') {
        if (view2.name === 'racecard' && view2.collection === 'race-meetings') {
            return view1.selected === view2.selected;
        } else {
            return false;
        }
    }

    if (view1.name === 'racecard') {
        return view2.name === 'racecard' && view2.collection === view1.collection && view2.selected === view1.selected;
    }

    if (view1.name === 'competition') {
        return view2.name === 'competition' && view2.id === view1.id;
    }

    if (view1.name === 'sport') {
        return view2.name === 'sport' && view2.id === view1.id;
    }

    if (view1.name === 'virtuals') {
        return view2.name === 'virtuals';
    }

    if (view1.name === 'login') {
        return view2.name === 'login';
    }

    if (view1.name === 'forgot-password') {
        return view2.name === 'forgot-password';
    }

    if (view1.name === 'signup') {
        return view2.name === 'signup';
    }

    if (view1.name === 'homepage') {
        return view2.name === 'homepage';
    }

    if (view1.name === 'inplay') {
        return view2.name === 'inplay';
    }

    if (view1.name === 'casino') {
        return view2.name === 'casino';
    }

    if (view1.name === 'live-casino') {
        return view2.name === 'live-casino';
    }

    if (view1.name === 'navigation-sports') {
        return view2.name === 'navigation-sports';
    }

    if (view1.name === 'notificationId') {
        return view2.name === 'notificationId';
    }

    if (view1.name === 'notifications-list') {
        return view2.name === 'notifications-list';
    }

    if (view1.name === 'starevents') {
        return view2.name === 'starevents';
    }

    if (view1.name === 'search') {
        return view2.name === 'search';
    }

    if (view1.name === 'sport-special') {
        return view2.name === 'search';
    }

    if (view1.name === 'sports') {
        return view2.name === 'sports';
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (view1.name === 'error') {
        return view2.name === 'error';
    }

    return assertNever('isActive currentView.name', view1);
};
