import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    ForgotPasswordPageWrapper,
    ForgotPasswordLogoWrapper,
    ForgotPasswordFormWrapper,
} from './ForgotPasswordPage.style';
import { UniverseComponent } from 'src/domains/common/universes';
import { ForgotPasswordTab } from 'src/domains/players/webview/components/Account/forgotPasswordTab/ForgotPasswordTab';

export const ForgotPasswordPage = observer('LoginPage', () => {
    return (
        <ForgotPasswordPageWrapper>
            <ForgotPasswordLogoWrapper>
                <UniverseComponent>
                    {(universeVersion): React.ReactElement => (
                        <universeVersion.headerLink dataTest='operator-logo'>
                            <universeVersion.headerLogo />
                        </universeVersion.headerLink>
                    )}
                </UniverseComponent>
            </ForgotPasswordLogoWrapper>
            <ForgotPasswordFormWrapper>
                <ForgotPasswordTab />
            </ForgotPasswordFormWrapper>
        </ForgotPasswordPageWrapper>
    );
});
