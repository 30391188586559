import React from 'react';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { observer } from 'src/utils/mobx-react';
import { RaceBoxSilkAndTrap } from './WidgetSilkAndTrap';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { getHorseRacingRunner } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { useCommon } from 'src/domains/common/Common';
import { SelectionWrapper, SilkCellRaceBox } from './RaceBoxSelection.style';
import {
    RunnerCell,
    RunnerCellsWrapper,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';

interface PropsType {
    defaultSilk: string;
    selectionId: SelectionId;
    specialEvent?: boolean;
}

export const RaceBoxSelectionHR = observer(
    'RaceBoxSelectionHR',
    ({ selectionId, defaultSilk, specialEvent }: PropsType) => {
        const common = useCommon();
        const config = ConfigComponents.get(common).config;

        const selection = selectionId.getModel();
        const market = selection?.marketId2.getModel();

        if (selection === null) {
            return null;
        }

        if (market === null || market === undefined) {
            return null;
        }

        const isUnnamedFavourite = selection.templateId === 'unnamed-favourite';
        const silkUrl = selection.metaDataSilkUrl ?? null;
        const silkMetaData = selection.metaDataSilk ?? null;
        const runner = getHorseRacingRunner(selection, isUnnamedFavourite);

        return (
            <>
                {specialEvent === true ? (
                    <RaceBoxSilkAndTrap
                        defaultSilk={defaultSilk}
                        selectionId={selectionId}
                    />
                ) : (
                    <SilkCellRaceBox>
                        <LazyImage
                            src={silkUrl}
                            placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                            alt={silkMetaData}
                        />
                    </SilkCellRaceBox>
                )}

                <RunnerCellsWrapper>
                    <RunnerCell>{runner}</RunnerCell>
                </RunnerCellsWrapper>

                <SelectionWrapper>
                    <Selection
                        borderLeft={true}
                        selectionId={selectionId}
                    />
                </SelectionWrapper>
            </>
        );
    }
);
