import React from 'react';

import trap1 from './assets/trap-1.webp';
import trap2 from './assets/trap-2.webp';
import trap3 from './assets/trap-3.webp';
import trap4 from './assets/trap-4.webp';
import trap5 from './assets/trap-5.webp';
import trap6 from './assets/trap-6.webp';

import trapSvg1 from './svgAssets/trap-1.svg';
import trapSvg2 from './svgAssets/trap-2.svg';
import trapSvg3 from './svgAssets/trap-3.svg';
import trapSvg4 from './svgAssets/trap-4.svg';
import trapSvg5 from './svgAssets/trap-5.svg';
import trapSvg6 from './svgAssets/trap-6.svg';

import { observer } from 'src/utils/mobx-react';
import { TrapImg } from 'src/domains/sportsbook/webview/components/trap/Trap.style';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

const TRAPS: Record<string, string> = {
    '1': trap1,
    '2': trap2,
    '3': trap3,
    '4': trap4,
    '5': trap5,
    '6': trap6,
};

const TRAPS_NEW: Record<string, string> = {
    '1': trapSvg1,
    '2': trapSvg2,
    '3': trapSvg3,
    '4': trapSvg4,
    '5': trapSvg5,
    '6': trapSvg6,
};

interface PropsType {
    className?: string;
    number: string | number | undefined | null;
    placeholder: string;
    selectionId: SelectionId | null;
}

export const Trap = observer('Trap', ({ number, placeholder, className, selectionId }: PropsType) => {
    const common = useCommon();
    const { config } = ConfigComponents.get(common);

    const selectionModel = selectionId === null ? null : selectionId.getModel();
    const eventModel = selectionModel === null ? null : selectionModel.getEvent();
    const marketModel = selectionModel === null ? null : selectionModel.getMarket();
    const isInternationalGreyhoundRacing =
        eventModel === null ? false : eventModel.sportOriginal === 'internationalgreyhoundracing';

    if (number === undefined && marketModel !== null && selectionId !== null) {
        const order = marketModel.selectionsIds.indexOf(selectionId.toOldId()) + 1;
        number = order;
    }

    if ((typeof number === 'string' || typeof number === 'number') && isInternationalGreyhoundRacing === false) {
        const trapImage = config.nextRacesNew ? TRAPS_NEW[number] : TRAPS[number];

        if (typeof trapImage === 'string') {
            return (
                <TrapImg
                    className={className}
                    src={trapImage}
                    alt={`trap number ${number}`}
                />
            );
        }
    }

    return (
        <TrapImg
            className={className}
            src={placeholder}
            alt='trap placeholder'
        />
    );
});
