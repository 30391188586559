import styled from '@emotion/styled';

export const LoginPageWrapper = styled('div', { label: 'LoginPageWrapper' })`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
`;

export const LoginLogoWrapper = styled('div', { label: 'LoginLogoWrapper' })`
    display: flex;
    justify-content: center;
`;

export const LoginFormWrapper = styled('div', { label: 'LoginFormWrapper' })`
    max-width: 600px;
    width: 100%;
`;
