import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { LiveTvIcon } from 'src/domains/layouts/shared/assets/icons/LiveTvIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';
import { SecondaryHeaderLoader } from 'src/domains/layouts/shared/loaders';

export const RaceBoxLoaderWrapper = styled('div', { label: 'RaceBoxLoaderWrapper' })`
    user-select: none;
    position: relative;
`;

export const RaceBoxHeaderLoader = styled(SecondaryHeaderLoader, { label: 'RaceBoxHeaderLoader' })`
    border-radius: 8px 8px 0 0;
`;

interface RaceBoxType {
    displaySeparator: boolean;
}

export const HeaderLinkWrapper = withConfig(theme => styled(Link, { label: 'HeaderLinkWrapper' })`
    background-color: ${theme.star.racingNextOff.raceBox.bgColorSecondary};
    border-radius: 8px 8px 0 0;
    color: ${theme.star.racingNextOff.raceBox.txtColorTertiary};
    display: flex;
    flex-flow: row wrap;
    height: 48px;
    padding: 8px 16px;
    position: relative;
    text-decoration: none;
`);

export const StreamIco = styled(LiveTvIcon, { label: 'StreamIco' })`
    bottom: 0;
    fill: currentcolor;
    left: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 16px;
`;

interface HeaderRaceNamePropsType {
    hasIcon: boolean;
}

export const HeaderRaceName = withConfig(theme => styled('h3', { label: 'HeaderRaceName' })<HeaderRaceNamePropsType>`
    display: flex;
    flex: 1 1 100%;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    margin: 0;
    overflow: hidden;
    padding-bottom: 4px;
    padding-left: ${({ hasIcon }): string => hasIcon ? '24px' : '0'};
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`);

const setSubHeaderText = (theme: EmotionTheme): SerializedStyles => {
    return css`
        line-height: 1.4;
        display: inline-block;
        margin: 0 8px 0 0;
        font-size: ${theme.star.fontSize.xSmall};
        font-weight: ${theme.star.fontWeight.regular};
        color: ${theme.star.racingNextOff.raceBox.txtColorQuaternary};
    `;
};

export const HeaderRaceTime = withConfig(theme => styled('span', { label: 'HeaderRaceTime' })<RaceBoxType>`
    ${setSubHeaderText(theme)};
    position: relative;
    ${({ displaySeparator }): SerializedStyles => displaySeparator ? css`
        &::after {
            content: '';
            position: absolute;
            right: -4px;
            top: 50%;
            width: 1px;
            height: 64%;
            transform: translateY(-50%);
            border-left: 1px solid currentcolor;
        }`
        : css``};
`);

export const HeaderRaceDistance = withConfig(theme => styled('span', { label: 'HeaderRaceDistance' })`
    ${setSubHeaderText(theme)};
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
`);

export const ShowMoreLink = withConfig(theme => styled(Link, { label: 'ShowMoreLink' })`
    color: ${theme.star.racingNextOff.raceBox.txtColor};
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 40px;
    line-height: 1.3334;
    padding: 12px 0 11px 16px;
    text-decoration: none;
    text-transform: capitalize;
`);

export const ShowMoreArrowWrapper = styled(ChevronIcon, { label: 'ShowMoreArrowWrapper' })`
    fill: currentcolor;
    margin-left: 4px;
    position: relative;
    width: 12px;
`;

export const RaceBoxList = styled('ul', { label: 'RaceBoxList' })`
    position: relative;
`;

export const RaceBoxListElement = withConfig(theme => styled('li', { label: 'RaceBoxListElement' })`
    align-items: center;
    background-color: ${theme.star.selectionsGroup.bgColor};
    border-bottom: 1px solid ${theme.star.racingNextOff.raceBox.borderColor};
    display: flex;
    flex-flow: row nowrap;
    height: 56px;
    &:last-of-type {
        border-radius: 0 0 8px 8px;
        height: 40px;
    }
`);

