import React from 'react';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { observer } from 'src/utils/mobx-react';
import { RaceBoxSilkAndTrap } from 'src/domains/sportsbook/webview/components/raceBoxSelection/WidgetSilkAndTrap';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import {
    JockeyDescription,
    RacerName,
    RaceBoxWrapper,
    RaceBoxWeight,
    SelectionWrapper,
    RaceBoxWeightTitle,
    RaceBoxSilk,
    LazyImageWrapper,
    TrapWrapper,
} from './RaceBoxSelection.style';

interface PropsType {
    defaultSilk: string;
    extendSelection: boolean;
    moreInfo: boolean;
    selectionId: SelectionId;
    specialEvent?: boolean;
    truncateRacerName?: boolean;
}

export const RaceBoxSelection = observer(
    'RaceBoxSelection',
    ({ selectionId, defaultSilk, moreInfo, extendSelection, specialEvent, truncateRacerName }: PropsType) => {
        const selection = selectionId.getModel();
        const market = selection?.marketId2.getModel();

        if (selection === null) {
            return null;
        }

        if (market === null) {
            return null;
        }

        const getDescription = (jockey: string | null, trainer: string | null): string => {
            if (jockey === null) {
                return trainer === null ? 'n/a' : trainer;
            }
            return trainer === null ? jockey : `${jockey} / ${trainer}`;
        };

        const jockey = selection.metaDataJockey ?? null;
        const trainer = selection.metaDataTrainer ?? null;
        const description = getDescription(jockey, trainer);
        const isUnnamedFavourite = selection.templateId === 'unnamed-favourite';
        const silkUrl = selection.metaDataSilkUrl;
        const isParticipantDescHidden =
            isUnnamedFavourite || moreInfo === false || (specialEvent === true && market?.isOutright === true);

        let extendContent;
        if (extendSelection && moreInfo && !isUnnamedFavourite) {
            extendContent = (
                <RaceBoxWeight className='race-box__selection__weight'>
                    <RaceBoxWeightTitle>Weight: </RaceBoxWeightTitle>
                    {selection.metaDataWeight ?? 'n/a'}
                </RaceBoxWeight>
            );
        }

        return (
            <>
                {specialEvent === true ? (
                    <RaceBoxSilkAndTrap
                        defaultSilk={defaultSilk}
                        selectionId={selectionId}
                    />
                ) : (
                    <RaceBoxSilk>
                        {moreInfo ? (
                            <LazyImageWrapper
                                src={silkUrl === null ? undefined : silkUrl}
                                placeholder={defaultSilk}
                                alt={selection.metaDataSilk}
                            />
                        ) : (
                            <TrapWrapper
                                number={selection.metaDataNumber}
                                placeholder={defaultSilk}
                                selectionId={selection.id2}
                            />
                        )}
                    </RaceBoxSilk>
                )}

                <RaceBoxWrapper isExtendSelection={extendSelection}>
                    <RacerName truncateRacerName={truncateRacerName}>{selection.name}</RacerName>
                    {isParticipantDescHidden ? null : <JockeyDescription>{description}</JockeyDescription>}
                </RaceBoxWrapper>

                {extendContent}

                <SelectionWrapper>
                    <Selection
                        borderLeft={true}
                        selectionId={selectionId}
                    />
                </SelectionWrapper>
            </>
        );
    }
);
