import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LoginPageWrapper, LoginLogoWrapper, LoginFormWrapper } from './LoginPage.style';
import { UniverseComponent } from 'src/domains/common/universes';
import { LoginTab } from 'src/domains/players/webview/components/Account/loginTab/LoginTab';

export const LoginPage = observer('LoginPage', () => {
    return (
        <LoginPageWrapper>
            <LoginLogoWrapper>
                <UniverseComponent>
                    {(universeVersion): React.ReactElement => (
                        <universeVersion.headerLink dataTest='operator-logo'>
                            <universeVersion.signUpLogo />
                        </universeVersion.headerLink>
                    )}
                </UniverseComponent>
            </LoginLogoWrapper>
            <LoginFormWrapper>
                <LoginTab />
            </LoginFormWrapper>
        </LoginPageWrapper>
    );
});
