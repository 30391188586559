import styled from '@emotion/styled';

export const ForgotPasswordPageWrapper = styled('div', { label: 'ForgotPasswordPageWrapper' })`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
`;

export const ForgotPasswordLogoWrapper = styled('div', { label: 'ForgotPasswordLogoWrapper' })`
    display: flex;
    justify-content: center;
`;

export const ForgotPasswordFormWrapper = styled('div', { label: 'ForgotPasswordFormWrapper' })`
    max-width: 600px;
    width: 100%;

    & > div {
        background-color: transparent;
    }
`;
