import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import { getPlace, getRunner } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { Trap } from 'src/domains/sportsbook/webview/components/trap/Trap';
import EMPTY_GH_SILK from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import EMPTY_TRAP from 'src/domains/sportsbook/webview/components/trap/svgAssets/trap-0.svg';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { RaceResultsRowCaption, ResultCell, ResultSelectionCell } from './RaceResults.style';
import {
    PlaceCell,
    RunnerCell,
    SilkCell,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';

interface RaceResultsRowPropsType {
    selection: SelectionModel;
    sport: 'horseracing' | 'greyhoundracing';
}

export const RaceResultsRow = observer('RaceResultsRow', (propsIn: RaceResultsRowPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { selection, sport } = props;
    const common = useCommon();
    const { config } = ConfigComponents.get(common);

    const marketModel = selection.getMarket();
    if (marketModel === null) {
        return null;
    }

    const eventModel = marketModel.getEvent();
    if (eventModel === null) {
        return null;
    }

    const selectionResult = selection.resultPlace === null ? 1 : selection.resultPlace;
    const place = getPlace(selection);
    const silkUrl = selection.metaDataSilkUrl;
    const isUnnamedFavourite = selection.templateId === 'unnamed-favourite';
    const runner = getRunner(selection, isUnnamedFavourite, true, true);
    const selectionSp = selection.spPrice === null ? '-' : selection.spPrice.f;

    if (isUnnamedFavourite) {
        return null;
    }

    return (
        <RaceResultsRowCaption>
            <ResultCell>{selectionResult}</ResultCell>
            {sport === 'horseracing' ? <PlaceCell>{place}</PlaceCell> : null}
            <SilkCell>
                {sport === 'horseracing' ? (
                    <LazyImage
                        src={silkUrl}
                        placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                        alt={selection.metaDataSilk}
                    />
                ) : (
                    <Trap
                        number={selection.metaDataNumber}
                        placeholder={config.nextRacesNew ? EMPTY_TRAP : EMPTY_GH_SILK}
                        selectionId={selection.id2}
                    />
                )}
            </SilkCell>
            <RunnerCell>{runner}</RunnerCell>
            <ResultSelectionCell>{selectionSp}</ResultSelectionCell>
        </RaceResultsRowCaption>
    );
});
