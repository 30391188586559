import { UniverseType } from 'src_common/common/universe';

// eslint-disable-next-line @typescript-eslint/naming-convention
type Routes = Record<string, string>;

const standardRoutes = {
    '/': 'homepage',

    '/login': 'login',
    '/forgot-password': 'forgot-password',

    '/account/:account/:static': 'homepage',
    '/account/:account': 'homepage',

    '/event/:id/:slug': 'event',
    '/event/:id': 'event',

    '/event/:id/:eventId/:slug/:type': 'sport',
    '/event/:id/:eventId/:slug': 'sport',
    '/event/:id/:eventId': 'sport',

    '/sport/:id/:type': 'sport',
    '/sport/:id': 'sport',
    '/sport-special/:id': 'sport-special',

    '/competition/:id/:slug': 'competition',
    '/competition/:id': 'competition',

    '/racecard/:sport/:collection/:selected': 'racecard',
    '/racecard/:sport/:collection/:racecardBuildIds/:selected': 'racecard',
    '/racecard/:sport/:collection': 'racecard',

    '/search': 'search',

    '/inplay': 'inplay',

    '/navigation-sports': 'navigation-sports',
    '/notifications-list': 'notifications-list',

    '/page/:id': 'page',
    '/error/:code': 'error',

    '/home': 'landing-home', //no component on /home check index.init.tsx

    // Auth
    '/signup': 'signup',
};

const starRoutesConfig = {
    ...standardRoutes,
    '/starevents': 'starevents',
    '/sports': 'sports',
};

const betzoneRoutesConfig = {
    ...standardRoutes,
    '/betzoneevents': 'starevents',
};

const swiftyRoutesConfig = {
    ...standardRoutes,
    '/specials': 'starevents',
};

const yeeehaaaRoutesConfig = {
    ...standardRoutes,
    '/yeeehaaaevents': 'starevents',
};

const dragonBetRoutesConfig = {
    ...standardRoutes,
    '/welshevents': 'starevents',
    '/sports': 'sports',
    '/sport': 'sports',
};

const akBetsRoutesConfig = {
    ...standardRoutes,
    '/ak-main-events': 'starevents',
};

const defaultRoutesConfig = {
    ...standardRoutes,
    '/promoted-events': 'starevents',
};

const routesExtendsByCasino = (routes: Routes): Routes => ({
    ...routes,
    '/casino': 'casino',
    '/casino/:gameid': 'casino',
    '/casino/proceed/:sessionId': 'casino',
    '/virtuals': 'virtuals',
    '/virtuals/:activeGameId': 'virtuals',
    '/virtuals/proceed/:sessionId': 'virtuals',
    '/live-casino': 'live-casino',
    '/live-casino/:gameid': 'live-casino',
    '/live-casino/proceed/:sessionId': 'live-casino',
});

const getPathForUniverse = (universe: UniverseType): Routes => {
    if (universe === 'star') {
        return starRoutesConfig;
    }

    if (universe === 'betzone') {
        return betzoneRoutesConfig;
    }

    if (universe === 'swiftyglobal') {
        return swiftyRoutesConfig;
    }

    if (universe === 'yeeehaaa') {
        return yeeehaaaRoutesConfig;
    }

    if (universe === 'dragonbet') {
        return dragonBetRoutesConfig;
    }

    if (universe === 'summitbet') {
        return defaultRoutesConfig;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (universe === 'akbets') {
        return akBetsRoutesConfig;
    }

    return standardRoutes;
};

export const configRoutes = (universe: UniverseType): Routes => {
    const universeRoutes = getPathForUniverse(universe);

    return routesExtendsByCasino(universeRoutes);
};
