import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SectionHeader } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader';
import { NextRacesHomePageHeader } from './NextRacesHeader';
import { NextRacesSlider } from './racesSlider/NextRacesSlider';
import { useCommon } from 'src/domains/common/Common';
import { NextRacesState } from './NextRaces.state';
import { RacesSliderSection } from './racesSlider/NextRacesSlider.style';
import {
    MainSectionHR,
    MainSectionWrapper,
} from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader.style';

export const NextRacesHomePage = observer('NextRacesHomePage', () => {
    const common = useCommon();
    // const nextRacesState = NextRacesState.get(common);
    const [nextRacesState] = React.useState(() => new NextRacesState(common));
    const { isNextRacesOpen, toggleNextRacesView } = nextRacesState;

    return (
        <>
            <MainSectionWrapper>
                <SectionHeader
                    title={
                        <I18n
                            langKey='special-box.header.next-races'
                            defaultText='Next Races'
                        />
                    }
                    isOpen={isNextRacesOpen}
                    handleOnToggle={toggleNextRacesView}
                />
                {isNextRacesOpen ? (
                    <RacesSliderSection>
                        <NextRacesHomePageHeader nextRacesState={nextRacesState} />
                        <NextRacesSlider nextRacesState={nextRacesState} />
                    </RacesSliderSection>
                ) : null}
            </MainSectionWrapper>

            {isNextRacesOpen ? null : <MainSectionHR />}
        </>
    );
});
