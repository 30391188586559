import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { NextRacesState } from 'src/domains/sportsbook/webview/components/nextRaces/NextRaces.state';
import { NextRacesSliderList } from './NextRacesSliderList';

interface NextRacesSliderSectionPropsType {
    nextRacesState: NextRacesState;
}
export const NextRacesSlider = observer('NextRacesSlider', (props: NextRacesSliderSectionPropsType) => {
    const { nextRacesState } = props;

    return (
        <NextRacesSliderList
            key='next-off'
            nextRacesState={nextRacesState}
        />
    );
});
