import React from 'react';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { observer } from 'src/utils/mobx-react';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { RaceBoxSilkAndTrap } from './WidgetSilkAndTrap';
import {
    RaceBoxSilk,
    RaceBoxWrapper,
    RacerName,
    SelectionWrapper,
    TrapWrapperNextRaces,
} from './RaceBoxSelection.style';

interface PropsType {
    defaultSilk: string;
    selectionId: SelectionId;
    specialEvent?: boolean;
    truncateRacerName?: boolean;
}

export const RaceBoxSelectionGR = observer(
    'RaceBoxSelectionGR',
    ({ selectionId, defaultSilk, specialEvent, truncateRacerName }: PropsType) => {
        const selection = selectionId.getModel();
        const market = selection?.marketId2.getModel();

        if (selection === null) {
            return null;
        }

        if (market === null || market === undefined) {
            return null;
        }

        return (
            <>
                {specialEvent === true ? (
                    <RaceBoxSilkAndTrap
                        defaultSilk={defaultSilk}
                        selectionId={selectionId}
                    />
                ) : (
                    <RaceBoxSilk>
                        <TrapWrapperNextRaces
                            number={selection.metaDataNumber}
                            placeholder={defaultSilk}
                            selectionId={selection.id2}
                        />
                    </RaceBoxSilk>
                )}

                <RaceBoxWrapper>
                    <RacerName truncateRacerName={truncateRacerName}>{selection.name}</RacerName>
                </RaceBoxWrapper>

                <SelectionWrapper>
                    <Selection
                        borderLeft={true}
                        selectionId={selectionId}
                    />
                </SelectionWrapper>
            </>
        );
    }
);
