import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { sortGreyhoundsSelections, sortSelections } from 'src/domains/layouts/utils/sortSelections';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { range } from 'lodash';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { ScrollArrows } from 'src/domains/sportsbook/webview/components/scrollArrows/ScrollArrows';
import emptyHrSilk_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import emptyHrSilk from 'src/domains/sportsbook/webview/components/racesSlider/assets/empty-hr-silk.svg';
import emptyGhSilk from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import { NextRacesState } from 'src/domains/sportsbook/webview/components/nextRaces/NextRaces.state';
import { RaceBox } from 'src/domains/sportsbook/webview/components/nextRaces/raceBox/RaceBox';
import { RaceBoxLoader } from 'src/domains/sportsbook/webview/components/nextRaces/raceBox/RaceBoxLoader';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { RacingCard, RacingCardsSwiper, RaceSliderWrapper, RacesSliderInfo } from './NextRacesSlider.style';

export const numberOfItemsInRaceBox = 3;

interface OptionType {
    defaultSilk: string;
    sortSelections: (selections: Array<SelectionModel>) => Array<SelectionModel>;
}

const OPTIONS = (sport: string, useNewSilk: boolean): OptionType => {
    if (sport === 'horseracing') {
        return {
            defaultSilk: useNewSilk ? emptyHrSilk : emptyHrSilk_old,
            sortSelections: sortSelections,
        };
    } else {
        return {
            defaultSilk: emptyGhSilk,
            sortSelections: sortGreyhoundsSelections,
        };
    }
};

interface NextRacesSliderPropsType {
    nextRacesState: NextRacesState;
}

export const NextRacesSliderList = observer('NextRacesSliderList', (props: NextRacesSliderPropsType) => {
    const common = useCommon();
    const { config } = ConfigComponents.get(common);
    const env = EnvironmentState.get(common);
    const { nextRacesState } = props;
    const { racesListToDisplay, eventsListToDisplayReady, sliderState, eventsCount } = nextRacesState;

    const detectFeatures = env.modernizrDetect;
    const isTouchEventsAvailable = detectFeatures !== null && detectFeatures.touchevents === true;

    const options = OPTIONS(nextRacesState.activeSportTab, config.shouldDisplaySilkWithoutQuestionMark);

    const {
        setRefSlider,
        setNextSlide,
        setPreviousSlide,
        calcShift,
        onTouchEnd,
        onTouchMove,
        onTouchStart,
        isLeftArrowActive,
        isRightArrowActive,
        isNotPossibleToSlide,
    } = sliderState;

    if (racesListToDisplay === 'loading') {
        return (
            <RaceSliderWrapper key='races-slider-races'>
                <RacingCardsSwiper
                    key='slider-swiper'
                    inProgress={true}
                >
                    {range(0, 12).map((x) => (
                        <RacingCard key={`placeholder-racing-card-${x}`}>
                            <RaceBoxLoader />
                        </RacingCard>
                    ))}
                </RacingCardsSwiper>
            </RaceSliderWrapper>
        );
    }

    if (eventsCount === 0) {
        return (
            <RacesSliderInfo>
                <I18n
                    langKey='races-slider.no-found-races'
                    defaultText="Sorry, we haven't found any races with such criteria."
                />
            </RacesSliderInfo>
        );
    }

    return (
        <RaceSliderWrapper key='races-slider-races'>
            <RacingCardsSwiper
                key='slider-swiper'
                ref={setRefSlider}
                inProgress={false}
                calcShift={calcShift}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                {eventsListToDisplayReady.map(([race, market]) => {
                    return (
                        <RacingCard
                            key={race.id2.key}
                            data-test-event-id={race.id2.toOldId()}
                        >
                            <RaceBox
                                race={race}
                                market={market}
                                defaultSilk={options.defaultSilk}
                                sortSelections={options.sortSelections}
                            />
                        </RacingCard>
                    );
                })}
            </RacingCardsSwiper>

            {isTouchEventsAvailable || isNotPossibleToSlide ? null : (
                <ScrollArrows
                    key='races-slider-pagination'
                    goLeftAvailable={isLeftArrowActive}
                    goRightAvailable={isRightArrowActive}
                    onLeft={setPreviousSlide}
                    onRight={setNextSlide}
                />
            )}
        </RaceSliderWrapper>
    );
});
