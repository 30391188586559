import { action, computed, observable, makeObservable } from 'mobx';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';

const SINGLE_CARD_WIDTH = 308;
const MIN_SWIPE_DISTANCE = 50;
const JUMP_INDICATOR = 1;

export class SliderState {
    @observable.ref private refPoint: number;
    @observable private refSlider: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);

    @observable private touchStart: number | null = null;
    @observable private touchEnd: number | null = null;

    public constructor(private readonly eventsCount: () => number) {
        makeObservable(this);
        this.refPoint = 0;
    }

    public get cardsCount(): number {
        return this.eventsCount();
    }

    @computed public get elemWidth(): null | number {
        if (this.refSlider.ref !== null) {
            return this.refSlider.ref.clientWidth;
        }
        return null;
    }

    @computed public get currentSlide(): number {
        return this.refPoint * JUMP_INDICATOR;
    }

    @computed public get cardsShown(): number {
        if (this.elemWidth === null) {
            return 0;
        }
        const cardsVisible = Math.floor(this.elemWidth / SINGLE_CARD_WIDTH);
        return this.currentSlide + cardsVisible;
    }

    @computed public get isLastCardVisible(): boolean {
        return this.cardsShown === this.cardsCount;
    }

    @computed public get isNotPossibleToSlide(): boolean {
        if (this.elemWidth === null) {
            return false;
        }
        return this.cardsCount * SINGLE_CARD_WIDTH < this.elemWidth;
    }

    @computed public get isLeftArrowActive(): boolean {
        return this.currentSlide > 0;
    }
    @computed public get isRightArrowActive(): boolean {
        return !this.isLastCardVisible;
    }

    @computed public get calcShift(): number {
        if (this.currentSlide === 0) {
            return 0;
        }

        if (this.cardsShown >= this.cardsCount) {
            const calcIndicator = this.cardsCount - JUMP_INDICATOR;
            const correct = this.elemWidth === null ? 0 : this.elemWidth - JUMP_INDICATOR * SINGLE_CARD_WIDTH;

            return -1 * SINGLE_CARD_WIDTH * calcIndicator + correct - 8;
        }
        return -1 * SINGLE_CARD_WIDTH * this.currentSlide;
    }

    @action public setRefSlider = (refSlider: HTMLElement | null): void => {
        this.refSlider.setRef(refSlider);
    };

    @action public setCurrentSlide = (newSlide: number): void => {
        this.refPoint = newSlide;
    };

    @action public setNextSlide = (): void => {
        if (!this.isLastCardVisible) {
            this.setCurrentSlide(this.currentSlide + 1);
        }
    };

    @action public setPreviousSlide = (): void => {
        if (this.currentSlide > 0) {
            this.setCurrentSlide(this.currentSlide - 1);
        }
    };

    @action public resetSlider = (): void => {
        this.refPoint = 0;
    };

    public onTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
        this.touchEnd = null;
        this.touchStart = e.targetTouches[0]?.clientX ?? null;
    };

    public onTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
        this.touchEnd = e.targetTouches[0]?.clientX ?? null;
    };

    public onTouchEnd = (): void => {
        if (this.touchStart === null || this.touchEnd === null) return;
        const distance = this.touchStart - this.touchEnd;
        const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
        const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;

        if (isLeftSwipe) {
            this.setNextSlide();
        }

        if (isRightSwipe) {
            this.setPreviousSlide();
        }
    };
}
