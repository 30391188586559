import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { NextRacesState, RaceSportType } from './NextRaces.state';
import { NextRacesSlider } from './racesSlider/NextRacesSlider';
import { NextRacesSportPageHeader } from './NextRacesHeader';
import { RacesSliderSection } from './racesSlider/NextRacesSlider.style';

interface NextRacesSportPagePropsType {
    nextRacesState: NextRacesState;
    sport: RaceSportType;
}
export const NextRacesSportPage = observer('NextRacesSportPage', (props: NextRacesSportPagePropsType) => {
    const { nextRacesState, sport } = props;

    return (
        <RacesSliderSection>
            <NextRacesSportPageHeader
                nextRacesState={nextRacesState}
                sport={sport}
            />
            <NextRacesSlider nextRacesState={nextRacesState} />
        </RacesSliderSection>
    );
});
