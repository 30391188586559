import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

const setDisabled = (theme: EmotionTheme, isDisabled: boolean): SerializedStyles | null => {
    if (isDisabled === true) {
        return css`
            background-color: ${theme.star.selection.bgColorSenary};
            color: ${theme.star.selection.txtColorSecondary};
            pointer-events: none;
            user-select: none;
        `;
    }
    return null;
};

const setSuspended = (theme: EmotionTheme): SerializedStyles | null => {
    return css`
        pointer-events: none;
        user-select: none;
        &::before {
            align-items: center;
            content: 'Susp';
            display: flex;
            font-size: ${theme.star.fontSize.small};
            font-weight: ${theme.star.fontWeight.regular};
            justify-content: center;
            line-height: 1.3334;
            margin: auto;
            opacity: 1;
            text-transform: uppercase;
            z-index: ${theme.star.zIndexGlobal.base};
            @media ${theme.star.mediaQuery.tablet} {
                font-size: ${theme.star.fontSize.xRegular};
            }
        }
    `;
};

const setEmpty = (isEmpty: boolean): SerializedStyles | null => {
    if (isEmpty === true) {
        return css`
            pointer-events: none;
            user-select: none;
        `;
    }
    return null;
};

const setBorders = (
    theme: EmotionTheme,
    borderTop: boolean,
    borderRight: boolean,
    borderBottom: boolean,
    borderLeft: boolean
): string => {
    const out: Array<string> = [];
    if (borderTop) {
        out.push(`border-top: 1px solid ${theme.star.selection.borderColor};`);
    }
    if (borderRight) {
        out.push(`border-right: 1px solid ${theme.star.selection.borderColor};`);
    }
    if (borderBottom) {
        out.push(`border-bottom: 1px solid ${theme.star.selection.borderColor};`);
    }
    if (borderLeft) {
        out.push(`border-left: 1px solid ${theme.star.selection.borderColor};`);
    }
    return out.join('\n');
};

const setTxtAndBackgroundColor = (
    theme: EmotionTheme,
    isSelected: boolean,
    isSuspended: boolean,
    isOddsDown: boolean,
    isOddsUp: boolean,
    isFinished: boolean
): SerializedStyles => {
    if (isFinished) {
        return css`
            background-color: transparent;
            color: ${theme.star.raceCardColors.txtColorFinishedRace};
            font-weight: ${theme.star.fontWeight.bold} !important;
            pointer-events: none;
            user-select: none;
        `;
    }
    if (isSelected === true) {
        return css`
            color: ${theme.star.selection.txtColorTertiary};
            &::before {
                background-color: ${theme.star.selection.bgColorQuinary};
                opacity: 1;
            }
            @media screen and (hover: hover) and (pointer: fine) {
                &::after {
                    background-color: ${theme.star.selection.bgColorSecondary};
                }
                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        `;
    }
    if (isSuspended === true) {
        return css`
            color: ${theme.star.odds.txtColorSecondary};
            &::before {
                background-color: ${theme.star.odds.bgColorTertiary};
                opacity: 1;
            }
        `;
    }
    if (isOddsDown === true) {
        return css`
            color: ${theme.star.odds.txtColor};
            &::before {
                background-color: ${theme.star.odds.bgColorSecondary};
                opacity: 1;
            }
        `;
    }
    if (isOddsUp === true) {
        return css`
            color: ${theme.star.odds.txtColor};
            &::before {
                background-color: ${theme.star.odds.bgColor};
                opacity: 1;
            }
        `;
    }

    return css`
        color: ${theme.star.selection.txtColor};
        background-color: ${theme.star.selection.bgColor};
        @media screen and (hover: hover) and (pointer: fine) {
            &:hover {
                color: ${theme.star.selection.txtColorTertiary};
                &::before {
                    background-color: ${theme.star.selection.bgColorQuinary};
                    opacity: 1;
                }
            }
        }
    `;
};

interface PropsType {
    borderBottom?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
    isDisabled?: boolean;
    isEmpty?: boolean;
    isFinished?: boolean;
    isOddsDown?: boolean;
    isOddsUp?: boolean;
    isSelected?: boolean;
    isSuspended?: boolean;
}

export const SelectionItemStyle = withConfig(theme => styled('a', { label: 'SelectionItemStyle' })<PropsType>`
    align-items: center;
    display: flex;
    flex: 0 0 48px;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    line-height: 1.43;
    margin-left: -1px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 0.2s ease;
    width: 48px;
    z-index: ${theme.star.zIndexGlobal.base};
    @media ${theme.star.mediaQuery.tablet} {
        flex-basis: 64px;
        font-size: ${theme.star.fontSize.xRegular};
        width: 64px;
    }
    &::after,
    &::before {
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.2s ease;
        z-index: ${theme.star.zIndexGlobal.below};
    }

    ${({
        isSelected = false,
        isSuspended = false,
        isOddsDown = false,
        isOddsUp = false,
        isFinished,
    }): SerializedStyles =>
        setTxtAndBackgroundColor(theme, isSelected, isSuspended, isOddsDown, isOddsUp, isFinished === true)};

    ${({ borderTop = false, borderRight = false, borderBottom = false, borderLeft = false }): string =>
        setBorders(theme, borderTop, borderRight, borderBottom, borderLeft)};

    ${({ isDisabled }): SerializedStyles | null => (isDisabled === true ? setDisabled(theme, isDisabled) : null)};

    ${({ isSuspended }): SerializedStyles | null => (isSuspended === true ? setSuspended(theme) : null)};

    ${({ isEmpty }): SerializedStyles | null => (isEmpty === true ? setEmpty(isEmpty) : null)};
`);

export const SelectionItemStyleNew = styled(SelectionItemStyle, { label: 'SelectionItemStyleNew' })`
    margin-left: 0;
`;

export const WinInProgress = withConfig(theme => styled('span', { label: 'WinInProgress' })`
    align-items: center;
    color: ${theme.star.odds.txtColorTertiary};
    border-left: 1px solid ${theme.star.selection.borderColor};
    border-right: 1px solid ${theme.star.selection.borderColor};
    display: flex;
    flex: 0 0 48px;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    line-height: 1.3334;
    margin-left: -1px;
    position: relative;
    transition: color 0.2s ease;
    z-index: ${theme.star.zIndexGlobal.base};
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xRegular};
        flex: 0 0 64px;
    }
    &::before {
        align-items: center;
        content: 'Susp';
        display: flex;
        margin: auto;
        text-transform: uppercase;
    }
`);

export const SpInProgress = withConfig(theme => styled(WinInProgress, { label: 'SpInProgress' })`
    border-left: 1px solid ${theme.star.selection.borderColor};
    display: none;
    @media ${theme.star.mediaQuery.tablet} {
        display: flex;
    }
`);

interface WinInProgressNewPropsType {
    isPriceHistory: boolean | undefined;
}
export const WinInProgressNew = withConfig(theme => styled('span', { label: 'WinInProgressNew' })<WinInProgressNewPropsType>`
    align-items: center;
    ${({ isPriceHistory }): string => isPriceHistory === true ? '' : `border-left: 1px solid ${theme.star.selection.borderColor};`};
    color: ${theme.star.selection.txtColorQuaternary};
    display: flex;
    flex: 0 0 48px;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    line-height: 1.3334;
    ${({ isPriceHistory }): string => isPriceHistory === true ? '' : 'margin-right: 1px;'};
    position: relative;
    transition: color 0.2s ease;
    z-index: ${theme.star.zIndexGlobal.base};
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xRegular};
        flex: 0 0 64px;
        margin-left: -1px;
        margin-right: 0;
    }
    &::before {
        align-items: center;
        content: 'Susp';
        display: flex;
        margin: auto;
        text-transform: uppercase;
    }
`);

export const SpInProgressNew = withConfig(theme => styled(WinInProgressNew, { label: 'SpInProgressNew' })<WinInProgressNewPropsType>`
    display: none;
    @media ${theme.star.mediaQuery.tablet} {
        border-left: 1px solid ${theme.star.selection.borderColor};
        display: flex;
        margin-left: 0;
        margin-right: 1px;
    }
`);
