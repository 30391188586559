import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Switch } from 'src/domains/layouts/webview/components/Switch';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { NextRacesState } from 'src/domains/sportsbook/webview/components/nextRaces/NextRaces.state';
import {
    SwitchLabel,
    SwitchWrapper,
} from 'src/domains/sportsbook/webview/components/nextRaces/racesSlider/NextRacesSlider.style';

interface TogglePropsType {
    nextRacesState: NextRacesState;
}
export const RacesRegionToggle = observer('RacesRegionToggle', (props: TogglePropsType) => {
    const { toggleRacesRegion, isRacesRegionSelected, racesListToDisplay } = props.nextRacesState;

    if (racesListToDisplay === 'loading' || racesListToDisplay.ukIrelandEvents.length === 0) {
        return null;
    }

    return (
        <SwitchWrapper switchedOn={isRacesRegionSelected}>
            <SwitchLabel switchedOn={isRacesRegionSelected}>
                <I18n
                    langKey='races-slider.header.switch-uk-ireland-only'
                    defaultText='UK & Ireland Only'
                />
            </SwitchLabel>
            <Switch
                onChange={toggleRacesRegion}
                switched={isRacesRegionSelected}
                version='primary'
            />
        </SwitchWrapper>
    );
});
