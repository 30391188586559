import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ScrollArrowsWrapper, ScrollLeft, ScrollRight } from './ScrollArrows.style';
import { ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';

interface PropsType {
    goLeftAvailable: boolean;
    goRightAvailable: boolean;
    onLeft: () => void;
    onRight: () => void;
}

export const ScrollArrows = observer('ScrollArrows', (props: PropsType) => {
    const { goLeftAvailable, onLeft, goRightAvailable, onRight } = props;
    const isLastCard = !goRightAvailable;

    const handleOnLeft = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        if (goLeftAvailable) {
            onLeft();
        }
    };

    const handleOnRight = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        if (goRightAvailable) {
            onRight();
        }
    };

    return (
        <ScrollArrowsWrapper>
            <ScrollLeft
                data-test='arrow-previous'
                isAvailable={goLeftAvailable}
                isLastCard={isLastCard}
                key='pagination-back'
                onClick={handleOnLeft}
            >
                <ButtonChevronIcon position='left' />
            </ScrollLeft>

            <ScrollRight
                data-test='arrow-next'
                isAvailable={goRightAvailable}
                key='pagination-forward'
                onClick={handleOnRight}
            >
                <ButtonChevronIcon position='right' />
            </ScrollRight>
        </ScrollArrowsWrapper>
    );
});
