import React from 'react';
import { range } from 'lodash';
import { observer } from 'src/utils/mobx-react';
import { RaceBoxListElement, RaceBoxList, RaceBoxLoaderWrapper, RaceBoxHeaderLoader } from './RaceBox.style';
import { ViewRaceCardLoader } from 'src/domains/layouts/shared/loaders';
import { RaceBoxSelectionLoader } from 'src/domains/sportsbook/webview/components/raceBoxSelection/RaceBoxSelectionLoader';
import { numberOfItemsInRaceBox } from 'src/domains/sportsbook/webview/components/nextRaces/racesSlider/NextRacesSliderList';

export const RaceBoxLoader = observer('RaceBoxLoader', () => {
    return (
        <RaceBoxLoaderWrapper>
            <RaceBoxHeaderLoader />
            <RaceBoxList key='race-box-selections'>
                {range(0, numberOfItemsInRaceBox).map((x) => (
                    <RaceBoxListElement key={x}>
                        <RaceBoxSelectionLoader />
                    </RaceBoxListElement>
                ))}
                <RaceBoxListElement>
                    <ViewRaceCardLoader />
                </RaceBoxListElement>
            </RaceBoxList>
        </RaceBoxLoaderWrapper>
    );
});
