import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export interface RacingCardsSwiperPropsType {
    inProgress: boolean;
    calcShift?: number;
}

export const RacingCardsSwiper = styled('nav', { label: 'RacingCardsSwiper' })<RacingCardsSwiperPropsType>`
    align-items: flex-start;
    display: flex;
    transform: ${({ calcShift }): string => calcShift === undefined ? '' :  `translateX(${calcShift}px)`};
    transition: transform .5s ease;
    width: 100%;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const RacingCard = styled('div', { label: 'RacingCard' })`
    flex-shrink: 0;
    margin-left: 8px;
    width: 300px;
`;

export const HomePageHeader = withConfig(theme => styled('div', { label: 'HomePageHeader' })`
    align-items: center;
    background-color: ${theme.star.racingNextOff.slider.bgColorSecondary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 8px 12px 8px;
`);

export const SportPageHeader = styled(HomePageHeader, { label: 'SportPageHeader' })`
    padding: 12px;
`;


export const RacesSliderSection = withConfig(theme => styled('section', { label: 'RacesSliderSection' })`
    background-color: ${theme.star.racingNextOff.slider.bgColor};
    color: ${theme.star.racingNextOff.slider.txtColor};
    overflow: hidden;
    position: relative;
`);

export const RacesSliderInfo = styled('div', { label: 'RacesSliderInfo' })`
    border-top: none;
    padding: 10px 5px;
    position: relative;
    text-align: center;
`;

export const RaceSliderWrapper = withConfig(theme => styled('div', { label: 'RaceSliderWrapper' })`
    background-color: ${theme.star.racingNextOff.slider.bgColorSecondary};
    position: relative;
    width: 100%;
    padding: 8px 0;
`);


export const SliderHeader = withConfig(theme => styled('h2', { label: 'SliderHeader' })`
    color: ${theme.star.headings.section.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0;
    user-select: none;
`);

export const RacesTypeSelectButtonsWrapper = styled('div', { label: 'RacesTypeSelectButtonsWrapper' })`
    display: flex;
    position: relative;
`;

interface SwitchLabelPropsType {
    switchedOn: boolean;
}

export const SwitchWrapper = styled('div', { label: 'SwitchWrapper' })<SwitchLabelPropsType>`
    align-items: center;
    display: flex;
    > div {
        margin-right: ${({ switchedOn }): string => switchedOn ? '12px': '0'};
    }
`;

export const SwitchLabel = withConfig(theme => styled('span', { label: 'SwitchLabel' })<SwitchLabelPropsType>`
    color: ${theme.star.racingNextOff.toggle.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.6;
    margin-right: ${({ switchedOn }): string => switchedOn ? '8px': '20px'};
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.small};
    }
`);
