import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Trap } from 'src/domains/sportsbook/webview/components/trap/Trap';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import { SerializedStyles, css } from '@emotion/react';
import {
    SilkCell,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';

export const SelectionWrapper = styled('div', { label: 'SelectionWrapper' })`
    display: flex;
    height: 100%;
`;

export const SilkCellRaceBox = withConfig(theme => styled(SilkCell, { label: 'SilkCellRaceBox' })`
    img {
        width: 42px;
    }
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 64px;
    }
`);

export const TrapWrapperNextRaces = styled(Trap, { label: 'TrapWrapperNextRaces' })`
    height: 32px;
    width: 32px;
`;

export const LazyImageWrapper = styled(LazyImage, { label: 'LazyImageWrapper' })`
    height: 22px;
    width: 29px;
`;

export const LazyImageWrapperNextRaces = styled('div', { label: 'LazyImageWrapperNextRaces' })`
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    flex-shrink: 0;
    justify-content: center;
    width: 32px;
`;

export const RaceBoxWrapper = styled('div', { label: 'RaceBoxWrapper' })`
    flex-flow: column nowrap;
    justify-content: center;
    display: flex;
    flex: 1 1 0%;
    height: 100%;
    overflow: hidden;
    padding-right: 8px;
`;

const setRaceDesc = (truncateText: boolean): SerializedStyles => {
    return css`
        display: block;
        text-align: left;
        width: 100%;
        ${truncateText === true ? css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `: null
        }
    `;
};

export const RacerName = withConfig(theme => styled('span', { label: 'RacerName' })<{truncateRacerName?: boolean}>`
    ${({ truncateRacerName }): SerializedStyles => setRaceDesc(truncateRacerName ?? true)}
    padding: ${({ truncateRacerName }): string => truncateRacerName === true ? '0' : '4px 0'};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const RaceBoxSilk = styled('div', { label: 'RaceBoxSilk' })`
    align-items: center;
    display: flex;
    flex: 0 0 64px;
    height: 100%;
    justify-content: center;
`;

