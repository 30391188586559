import { Resource } from 'src_common/common/mobx-utils/Resource';
import { action, computed, makeObservable } from 'mobx';
import { TrpcClient } from 'src/appState/TrpcClient';
import { withdrawalsList } from 'src_server/trpc/types/wallet';

export class WithdrawalsListState {
    private readonly withdrawalsResource: Resource<withdrawalsList.ResponseType>;

    public constructor(private readonly trpc: TrpcClient) {
        makeObservable(this);
        this.withdrawalsResource = new Resource(async (): Promise<withdrawalsList.ResponseType> => {
            return await this.trpc.client.walletRouter.withdrawalsList.query();
        });
    }

    @computed public get withdrawals(): withdrawalsList.SuccessResponseType | [] {
        const result = this.withdrawalsResource.get();
        if (result.type === 'ready' && result.value.responseStatus === 'success') {
            return result.value.response;
        }
        return [];
    }

    @action public cancelWithdrawal = async (id: number): Promise<void> => {
        await this.trpc.client.walletRouter.cancelWithdrawal.mutate({ transactionId: id }).then(() => this.refresh());
    };

    public async refresh(): Promise<void> {
        await this.withdrawalsResource.refresh();
    }
}
