import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { StreamingState } from 'src/domains/sportsbook/shared/Types';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { RaceBoxSelectionGR } from 'src/domains/sportsbook/webview/components/nextRaces/raceBoxSelection/RaceBoxSelectionGR';
import { RaceBoxSelectionHR } from 'src/domains/sportsbook/webview/components/nextRaces/raceBoxSelection/RaceBoxSelectionHR';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { DateTime } from 'src_common/utils/time/time';
import { distance as formatDistance } from 'src/domains/sportsbook/utils/format';
import { RacecardRaceRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { numberOfItemsInRaceBox } from 'src/domains/sportsbook/webview/components/nextRaces/racesSlider/NextRacesSliderList';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';
import {
    HeaderRaceDistance,
    HeaderRaceName,
    HeaderRaceTime,
    StreamIco,
    ShowMoreLink,
    RaceBoxListElement,
    RaceBoxList,
    ShowMoreArrowWrapper,
    HeaderLinkWrapper,
} from './RaceBox.style';

const getRaceName = (event: EventModel): string | undefined => {
    const nameFragments = /^(\d{2}:\d{2}) (.*)/.exec(event.name);
    return nameFragments?.pop();
};

const getRaceTime = (event: EventModel): string | undefined => {
    return DateTime.from(event.timeSettingsStartTime)?.format('HH:mm');
};

const getRaceDistance = (event: EventModel): string | undefined => {
    return event.feedDistance === undefined ? undefined : formatDistance(event.feedDistance);
};

const getRoutingData = (event: EventModel): RacecardRaceRouteType | undefined => {
    return {
        name: 'racecard',
        collection: event.competition2.toOldId(),
        sport: event.sport,
        selected: event.id2.toOldId(),
    };
};

const prepareDisplayOrder = (event: EventModel): string => {
    const tagsDisplayOrder = event.tagsDisplayOrder;
    if (tagsDisplayOrder !== undefined && tagsDisplayOrder !== '-') {
        return tagsDisplayOrder;
    }
    return event.sport === 'horseracing' ? 'by-price' : 'by-creation';
};

const getItemsForView = (
    event: EventModel,
    market: MarketModel,
    sortSelections: (list: Array<SelectionModel>) => Array<SelectionModel>
): SelectionModel[] | undefined => {
    const sortFromInterface = sortSelections(market.selections)
        .filter((x) => x.resultType !== 'void' && x.display)
        .slice(0, numberOfItemsInRaceBox);

    if (event.sport === 'greyhoundracing') {
        return sortFromInterface;
    }

    return sortSelectionsByCriteria(sortFromInterface, prepareDisplayOrder(event));
};

export interface RaceBoxPropsType {
    race: EventModel;
    market: MarketModel;
    defaultSilk: string;
    sortSelections: (list: Array<SelectionModel>) => Array<SelectionModel>;
}

export const RaceBox = observer('RaceBox', (props: RaceBoxPropsType) => {
    const { race, market, defaultSilk, sortSelections } = props;
    const common = useCommon();
    const streamingState = StreamingState.get(common);
    const { config } = ConfigComponents.get(common);
    const hasStream = config.hasEventLiveStreaming && streamingState.hasStream(race.id2.toOldId());
    const time = getRaceTime(race);
    const distance = getRaceDistance(race);
    const routingData = getRoutingData(race);
    const itemsForView = getItemsForView(race, market, sortSelections);

    return (
        <>
            <HeaderLinkWrapper
                dataTest='event'
                to={routingData}
                key='race-box__header-link'
            >
                <HeaderRaceName hasIcon={hasStream}>
                    {hasStream ? <StreamIco /> : null}
                    {getRaceName(race)}
                </HeaderRaceName>
                <HeaderRaceTime displaySeparator={distance !== undefined}>{time}</HeaderRaceTime>
                <HeaderRaceDistance>{distance}</HeaderRaceDistance>
            </HeaderLinkWrapper>
            <RaceBoxList key='race-box-selections'>
                {itemsForView?.map((selection, index) => {
                    if (race.sport === 'horseracing') {
                        return (
                            <RaceBoxListElement key={`${selection.id2.toOldId()}-${index}`}>
                                <RaceBoxSelectionHR
                                    defaultSilk={defaultSilk}
                                    selectionId={selection.id2}
                                />
                            </RaceBoxListElement>
                        );
                    } else {
                        return (
                            <RaceBoxListElement key={`${selection.id2.toOldId()}-${index}`}>
                                <RaceBoxSelectionGR
                                    defaultSilk={defaultSilk}
                                    selectionId={selection.id2}
                                />
                            </RaceBoxListElement>
                        );
                    }
                })}
                <RaceBoxListElement>
                    <ShowMoreLink to={routingData}>
                        <I18n
                            langKey='events.racebox.view.label'
                            defaultText='View racecard'
                        />
                        <ShowMoreArrowWrapper position='right' />
                    </ShowMoreLink>
                </RaceBoxListElement>
            </RaceBoxList>
        </>
    );
});
