import React from 'react';
import { PromoNotificationsModelForViewType } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { TCStyle, NotificationWrapper } from './Notification.style';
import { NotificationMedia } from './NotificationMedia';

interface PropsType {
    notification: PromoNotificationsModelForViewType;
}

export const Notification = observer('Notification', ({ notification }: PropsType) => {
    const id = notification.id;
    const content = notification.tc_text;

    return (
        <NotificationWrapper data-test={`promo-notification-item-${notification.title}`}>
            <NotificationMedia notification={notification} />
            {content === '' ? null : (
                <TCStyle
                    to={{ account: 'terms-and-conditions-promos', notificationId: id.toString() }}
                    dataTest='promo-notification-tc'
                >
                    <I18n
                        langKey='content.notification.terms-apply'
                        defaultText="T&C's apply"
                    />
                </TCStyle>
            )}
        </NotificationWrapper>
    );
});
